<template>
  <div class="meter-log-list-container">
    <b-row
      v-if="analytic"
      class="match-height"
    >
      <b-col
        cols="6"
        md="3"
      >
        <card-statistic
          title="Chưa chốt"
          :value="analytic.excludeMeters"
          icon="PenToolIcon"
          color="danger"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <card-statistic
          title="Đã duyệt"
          :value="analytic.approve"
          icon="CheckIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <card-statistic
          title="Chưa duyệt"
          :value="analytic.disapprove"
          icon="MinusIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <card-statistic
          title="Tổng tiêu thụ"
          :value="Number(analytic.delta).toLocaleString()"
          icon="TagIcon"
          color="secondary"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Chốt chỉ số</b-card-title>
        <crud-buttons
          name="Chỉ số"
          :selected-rows="selectedRows"
          modal="modal-meter-log-0"
          :enable-approve="$can('update', 'meter-log')"
          :enable-decline="$can('update', 'meter-log')"
          :show-import="$can('create', 'meter-log')"
          :show-export="true"
          :enable-add="$can('create', 'meter-log')"
          :hide-delete="!$can('delete', 'meter-log')"
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
          @on-import-file="importData"
          @download-template-file="downloadImportTemplate"
        >
          <template
            slot="other-buttons"
          >
            <b-button
              v-if="$can('approve', 'meter-log')"
              v-b-tooltip.hover.v-info
              variant="info"
              class="btn-icon ml-50"
              title="Duyệt"
              :hidden="setApproveHidden(selectedRows)"
              @click="onApproveClick(selectedRows)"
            >
              <feather-icon icon="CheckSquareIcon" />
            </b-button>
            <b-button
              v-if="$can('approve', 'meter-log')"
              v-b-tooltip.hover.v-secondary
              variant="secondary"
              class="btn-icon ml-50"
              title="Bỏ duyệt"
              :hidden="setDeclineHidden(selectedRows)"
              @click="onDeclineClick(selectedRows)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </template>
        </crud-buttons>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="2">
            <select-month
              v-model="month"
              :default-value="month"
            />
          </b-col>
          <b-col md="2">
            <select-approve-status
              v-model="approveStatus"
              :default-value="approveStatus"
            />
          </b-col>
          <b-col md="2">
            <select-meter-type
              v-model="type"
            />
          </b-col>
          <b-col md="3">
            <select-apartment v-model="apartment" />
          </b-col>
          <b-col md="3">
            <select-room
              v-model="room"
              :apartment="apartment"
            />
          </b-col>

        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-meter-log-detail
                    class="font-weight-bold"
                    @click="onSelectItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Approve -->
                <span v-else-if="props.column.field === 'meter.name'">
                  <span>{{ props.row.meter.name }}</span>
                  <span
                    v-if="props.row.apartment"
                    class="text-muted"
                  ><br>Tòa nhà: {{ props.row.apartment.name }}</span>
                  <span
                    v-if="props.row.room"
                    class="text-muted"
                  ><br>Phòng: {{ props.row.room.name }}</span>
                </span>

                <!-- Column: Approve -->
                <span v-else-if="props.column.field === 'approve'">
                  <b-badge
                    :variant="props.row.approve ? 'light-primary' : 'light-danger'"
                    class="font-small-1"
                  >
                    <span>{{ props.row.approve ? "Có" : "Chưa" }}</span>
                  </b-badge>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'delta' || props.column.field === 'firstIndex' || props.column.field === 'lastIndex'">
                  {{ Number(props.row[props.column.field]).toLocaleString() }}
                </span>

                <span v-else-if="props.column.field === 'issueDate'">
                  {{ parseDateToString(props.row.issueDate) }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="Chỉ số"
                    modal="modal-meter-log-0"
                    :disable-edit="props.row.approve"
                    :disable-delete="props.row.approve"
                    :hide-delete="!$can('delete', 'meter-log')"
                    :hide-edit="!$can('update', 'meter-log')"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button
                        v-if="!props.row.approve && $can('approve', 'meter-log')"
                        v-b-tooltip.hover.v-success
                        variant="success"
                        class="btn-icon mr-50"
                        title="Duyệt"
                        size="sm"
                        @click="onApproveClick([props.row])"
                      >
                        <feather-icon icon="CheckSquareIcon" />
                      </b-button>
                      <b-button
                        v-if="props.row.approve && $can('approve', 'meter-log')"
                        v-b-tooltip.hover.v-secondary
                        variant="secondary"
                        class="btn-icon mr-50"
                        title="Bỏ duyệt"
                        size="sm"
                        @click="onDeclineClick([props.row])"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>

                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <meter-log-modal
      :id="0"
      :item="item"
      @refetch-data="fetchData"
    />
    <meter-log-detail-modal
      v-if="selectedItem"
      :meter-log-id="selectedItem.id"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  VBTooltip,
  BButton,
  BBadge,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectMeterType from '@/views/components/SelectMeterType.vue';
import SelectMonth from '@/views/components/SelectMonth.vue';
import CardStatistic from '@/views/components/CardStatistic.vue';
import SelectApproveStatus from '@/views/components/SelectApproveStatus.vue';
// eslint-disable-next-line import/no-cycle
import { parseDateToString } from '@/auth/utils';
import useMeterLogList from './useMeterLogList';
import MeterLogModal from '../modal/MeterLogModal.vue';
import MeterLogDetailModal from '../detail/MeterLogDetailModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BButton,
    MeterLogModal,
    VueGoodTable,
    TableActions,
    CrudButtons,
    SelectApartment,
    SelectRoom,
    SelectMeterType,
    SelectMonth,
    CardStatistic,
    SelectApproveStatus,
    MeterLogDetailModal,

  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  setup() {
    const {
      analytic,
      item,
      selectedItem,
      columns,
      rows,
      type,
      apartment,
      room,
      approveStatus,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      month,
      fetchData,
      fetchMeterLogs,
      deleteMeterLogs,
      resetItem,
      onEditItem,
      onSelectItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      approveMeterLogs,
      setApproveHidden,
      setDeclineHidden,
      exportData,
      downloadImportTemplate,
      importData,
    } = useMeterLogList();

    return {
      analytic,
      item,
      selectedItem,
      columns,
      rows,
      type,
      apartment,
      room,
      month,
      approveStatus,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchMeterLogs,
      deleteMeterLogs,
      resetItem,
      onEditItem,
      onSelectItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      approveMeterLogs,
      setApproveHidden,
      setDeclineHidden,
      exportData,
      downloadImportTemplate,
      importData,
      parseDateToString,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(meterlog) {
      const deleteObjects = meterlog && meterlog.id > 0 ? [meterlog] : this.selectedRows;
      this.deleteMeterLogs(deleteObjects);
    },
    onApproveClick(items) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác DUYỆT chỉ số công tơ. Bạn có chắc chắn muốn xác nhận duyệt này không?`,
          {
            title: `Duyệt chỉ số công tơ`,
            okTitle: 'Duyệt',
            cancelTitle: 'Huỷ',
            okVariant: 'primary',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.approveMeterLogs(true, items);
          }
        });
    },
    onDeclineClick(items) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác BỎ DUYỆT chỉ số công tơ. Bạn có chắc chắn muốn hủy duyệt không?`,
          {
            title: `Bỏ duyệt chỉ số công tơ`,
            okTitle: 'Bỏ duyệt',
            cancelTitle: 'Huỷ',
            okVariant: 'warning',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.approveMeterLogs(false, items);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}

.meter-log-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(5) {
               min-width: 80px !important;
            }
            &:nth-child(6) {
               min-width: 80px !important;
            }
            &:nth-child(7) {
               min-width: 100px !important;
            }
            &:nth-child(8) {
               min-width: 120px !important;
            }
            &:nth-child(9) {
               min-width: 100px !important;
            }
          }
        }
      }
    }
}
</style>
